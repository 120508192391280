var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-entity',{attrs:{"config":{
    backRouteName: 'customers.index',
    getEntity: _vm.getEntity,
    onSubmit: _vm.onSubmit,
    showControlButtons: true
}},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var isUpdateMode = ref.isUpdateMode;
return [_vm._v(" "+_vm._s(isUpdateMode ? 'Edit Customer' : 'Create Customer')+" ")]}},{key:"form",fn:function(ref){
var isUpdateMode = ref.isUpdateMode;
return [_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('base-input',{attrs:{"name":"name","rules":{ required: true },"required":"","label":"Name","size":"sm"},model:{value:(_vm.entity.name),callback:function ($$v) {_vm.$set(_vm.entity, "name", $$v)},expression:"entity.name"}})],1),_c('b-col',{attrs:{"sm":"4"}},[_c('base-input',{attrs:{"name":"username","rules":{ required: true },"required":"","label":"Username"},model:{value:(_vm.entity.username),callback:function ($$v) {_vm.$set(_vm.entity, "username", $$v)},expression:"entity.username"}})],1),_c('b-col',{attrs:{"sm":"4"}},[_c('base-input',{attrs:{"name":"code","rules":{ required: true },"required":"","label":"Code"},model:{value:(_vm.entity.code),callback:function ($$v) {_vm.$set(_vm.entity, "code", $$v)},expression:"entity.code"}})],1),_c('b-col',{attrs:{"sm":"4"}},[_c('base-input',{attrs:{"type":"text","name":"phone","rules":{ required: true },"required":"","label":"Phone"},model:{value:(_vm.entity.phone),callback:function ($$v) {_vm.$set(_vm.entity, "phone", $$v)},expression:"entity.phone"}})],1),_c('b-col',{attrs:{"sm":"4"}},[_c('base-input',{attrs:{"type":"text","name":"email","rules":{ email: true },"label":"Email"},model:{value:(_vm.entity.email),callback:function ($$v) {_vm.$set(_vm.entity, "email", $$v)},expression:"entity.email"}})],1),_c('b-col',{attrs:{"sm":"4"}},[_c('base-input',{attrs:{"type":"text","name":"telegram_group_chat_id","label":"Telegram group chat id"},model:{value:(_vm.entity.telegram_group_chat_id),callback:function ($$v) {_vm.$set(_vm.entity, "telegram_group_chat_id", $$v)},expression:"entity.telegram_group_chat_id"}})],1),(!isUpdateMode)?_c('b-col',{attrs:{"sm":"4"}},[_c('base-input',{attrs:{"type":"password","name":"password","rules":{ required: true },"required":"","label":"Password"},model:{value:(_vm.entity.password),callback:function ($$v) {_vm.$set(_vm.entity, "password", $$v)},expression:"entity.password"}})],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
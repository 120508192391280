<template>
    <base-entity :config="{
        backRouteName: 'customers.index',
        getEntity: getEntity,
        onSubmit: onSubmit,
        showControlButtons: true
    }">
        <template v-slot:title="{ isUpdateMode }">
            {{ isUpdateMode ? 'Edit Customer' : 'Create Customer'}}
        </template>

        <template v-slot:form="{ isUpdateMode }">
            <b-row>
                <b-col sm="4">
                    <base-input v-model="entity.name" name="name" :rules="{ required: true }" required label="Name"
                        size="sm">
                    </base-input>
                </b-col>
                <b-col sm="4">
                    <base-input v-model="entity.username" name="username" :rules="{ required: true }" required
                        label="Username">
                    </base-input>
                </b-col>
                <b-col sm="4">
                    <base-input v-model="entity.code" name="code" :rules="{ required: true }" required label="Code">
                    </base-input>
                </b-col>
                <b-col sm="4">
                    <base-input v-model="entity.phone" type="text" name="phone" :rules="{ required: true }" required
                        label="Phone">
                    </base-input>
                </b-col>
                <b-col sm="4">
                    <base-input v-model="entity.email" type="text" name="email" :rules="{ email: true }" label="Email">
                    </base-input>
                </b-col>
                <b-col sm="4">
                    <base-input v-model="entity.telegram_group_chat_id" type="text" name="telegram_group_chat_id"
                        label="Telegram group chat id">
                    </base-input>
                </b-col>
                <b-col sm="4" v-if="!isUpdateMode">
                    <base-input v-model="entity.password" type="password" name="password" :rules="{ required: true }"
                        required label="Password">
                    </base-input>
                </b-col>
            </b-row>
        </template>
    </base-entity>
</template>
<script>
import BaseEntity from '@/components/Custom/BaseEntity';
import store from './store';
export default {
    data() {
        return {
            entity: {
                name: '',
                username: '',
                code: '',
                password: '',
                phone: '',
                email: '',
                telegram_group_chat_id: ''
            }
        }
    },
    methods: {
        onSubmit(id) {
            if (id) {
                store.dispatch('update', this.entity).then(() => {
                    this.toastSuccess('Customer Updated');
                    this.$router.push({ name: 'customers.index' });
                });
            } else {
                store.dispatch('store', this.entity).then(() => {
                    this.toastSuccess('Customer Created');
                    this.$router.push({ name: 'customers.index' });
                });
            }
        },
        getEntity(id) {
            store.dispatch('show', id).then(({ data }) => {
                this.entity = data;
            });
        }
    },
    components: {
        BaseEntity
    }
}
</script>
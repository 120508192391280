import CustomerRequest from '@requests/customer_request';

export default {
    getEntities({ commit }, filter) {
        CustomerRequest.index(filter)
            .then((data) => {
                commit('SET_ENTITIES', data);
            });
    },
    store(_, payload) {
        return CustomerRequest.store(payload);
    },
    update(_, entity) {
        return CustomerRequest.update(entity.id, entity);
    },
    show(_, id) {
        return CustomerRequest.show(id);
    },
    destroy(_, entity) {
        return CustomerRequest.destroy(entity.id);
    }
}